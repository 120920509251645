import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { Trash2, HelpCircle, Plus } from 'lucide-react';

function CropPlanner() {
  const [crops, setCrops] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [toasts, setToasts] = useState([]);

  const { control, handleSubmit, formState: { errors }, reset, getValues } = useForm({
    defaultValues: {
      farm_info: {
        name: '',
        year: new Date().getFullYear(),
        hardiness_zone: '',
        first_frost_date: '',
        last_frost_date: '',
      },
      new_crop: {
        name: '',
        start_indoor_weeks: '',
        transplant_weeks_after_last_frost: '',
        direct_sow_weeks: '',
        harvest_weeks: '',
        successions: '',
        days_to_maturity: '',
      }
    }
  });

  useEffect(() => {
    const loadRecaptchaScript = () => {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?render=6Le0xFQqAAAAAHMFq78iLFQn3dtHwnapuaDFLl7f';
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    };

    loadRecaptchaScript();

    return () => {
      const script = document.querySelector('script[src^="https://www.google.com/recaptcha/api.js"]');
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, []);

  const executeRecaptcha = useCallback(() => {
    return new Promise((resolve, reject) => {
      if (window.grecaptcha && window.grecaptcha.execute) {
        window.grecaptcha.execute('6Le0xFQqAAAAAHMFq78iLFQn3dtHwnapuaDFLl7f', { action: 'submit' })
          .then(token => resolve(token))
          .catch(error => reject(error));
      } else {
        reject(new Error('reCAPTCHA has not loaded yet.'));
      }
    });
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  const showToast = (message, type) => {
    const id = Date.now();
    setToasts(prevToasts => [...prevToasts, { id, message, type }]);
    setTimeout(() => removeToast(id), 5000);
  };

  const removeToast = (id) => {
    setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id));
  };

  const onSubmit = async (data) => {
    if (crops.length === 0) {
      setAlert({ type: 'error', message: 'Please add at least one crop before generating the plan.' });
      showToast('Please add at least one crop before generating the plan.', 'error');
      return;
    }

    setIsLoading(true);
    try {
      const recaptchaToken = await executeRecaptcha();
      
      const formattedData = {
        farm_info: {
          ...data.farm_info,
          first_frost_date: formatDate(data.farm_info.first_frost_date),
          last_frost_date: formatDate(data.farm_info.last_frost_date)
        },
        crop_data: crops,
        recaptcha: recaptchaToken
      };

      console.log('Sending data:', formattedData);

      const response = await axios.post('https://agbata-crop-planner-backend-51b4187baaa6.herokuapp.com/api/generate_crop_plan', formattedData, {
        headers: {
          'Authorization': `Bearer 02e27e52-513e-4c16-a476-4079515d5947`,
          'Content-Type': 'application/json',
        },
        responseType: 'blob',
      });
      
      console.log('Response received:', response);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'crop_plan.xlsx');
      document.body.appendChild(link);
      link.click();
      
      setAlert({ type: 'success', message: 'Crop plan generated successfully! Your download should begin shortly.' });
      showToast('Crop plan generated successfully!', 'success');
      setIsComplete(true);
    } catch (error) {
      console.error('Error generating crop plan:', error);
      setAlert({ type: 'error', message: 'Failed to generate crop plan. Please try again.' });
      showToast('Failed to generate crop plan. Please try again.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const addCrop = () => {
    const newCrop = getValues('new_crop');
    if (newCrop.name && newCrop.harvest_weeks && newCrop.successions && newCrop.days_to_maturity) {
      setCrops(prevCrops => [...prevCrops, newCrop]);
      reset(prevValues => ({
        ...prevValues,
        new_crop: {
          name: '',
          start_indoor_weeks: '',
          transplant_weeks_after_last_frost: '',
          direct_sow_weeks: '',
          harvest_weeks: '',
          successions: '',
          days_to_maturity: '',
        }
      }));
      setAlert({ type: 'success', message: `Crop "${newCrop.name}" added successfully.` });
      showToast(`Crop "${newCrop.name}" added successfully.`, 'success');
    } else {
      setAlert({ type: 'error', message: 'Please fill in all required fields for the crop.' });
      showToast('Please fill in all required fields for the crop.', 'error');
    }
  };

  const removeCrop = (index) => {
    setCrops(prevCrops => {
      const updatedCrops = [...prevCrops];
      const removedCrop = updatedCrops.splice(index, 1)[0];
      setAlert({ type: 'success', message: `${removedCrop.name} removed successfully.` });
      showToast(`${removedCrop.name} removed successfully.`, 'success');
      return updatedCrops;
    });
  };

  const renderTooltip = (content) => (
    <div className="group relative inline-block">
      <HelpCircle className="h-4 w-4 ml-1 inline-block cursor-help text-gray-500" />
      <div className="opacity-0 bg-black text-white text-xs rounded py-1 px-2 absolute z-10 group-hover:opacity-100 bottom-full left-1/2 ml-2 w-48">
        {content}
      </div>
    </div>
  );

  const hardinessZones = [
    '1a', '1b', '2a', '2b', '3a', '3b', '4a', '4b', '5a', '5b',
    '6a', '6b', '7a', '7b', '8a', '8b', '9a', '9b', '10a', '10b',
    '11a', '11b', '12a', '12b', '13a', '13b'
  ];

  return (
    // <div className="container mx-auto px-4 py-8">
    <div className="px-4 py-8">
      {/* <h1 className="text-3xl font-bold mb-8">Crop Planner</h1> */}
      {alert.message && (
        <div className={`mb-6 p-4 rounded ${alert.type === 'success' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
          <p className="font-bold">{alert.type === 'success' ? 'Success' : 'Error'}</p>
          <p>{alert.message}</p>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Farm Information</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="farm-name">
                Farm Name
                {renderTooltip("Enter the name of your farm")}
              </label>
              <Controller
                name="farm_info.name"
                control={control}
                rules={{ required: "Farm name is required" }}
                render={({ field }) => (
                  <div>
                    <input
                      {...field}
                      id="farm-name"
                      className={`mt-1 block w-full rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
                        errors.farm_info?.name ? 'border-red-500 focus:border-red-500 focus:ring-red-200' : 'border-gray-300 focus:border-gray-300 focus:ring-gray-200'
                      }`}
                      placeholder="Enter farm name"
                    />
                    {errors.farm_info?.name && <p className="text-red-500 text-xs mt-1">{errors.farm_info.name.message}</p>}
                  </div>
                )}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="year">
                Year
                {renderTooltip("Enter the year for which you're planning")}
              </label>
              <Controller
                name="farm_info.year"
                control={control}
                rules={{ required: "Year is required" }}
                render={({ field }) => (
                  <div>
                    <input
                      {...field}
                      id="year"
                      type="number"
                      className={`mt-1 block w-full rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
                        errors.farm_info?.year ? 'border-red-500 focus:border-red-500 focus:ring-red-200' : 'border-gray-300 focus:border-gray-300 focus:ring-gray-200'
                      }`}
                      placeholder="Enter year"
                    />
                    {errors.farm_info?.year && <p className="text-red-500 text-xs mt-1">{errors.farm_info.year.message}</p>}
                  </div>
                )}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="hardiness-zone">
                Hardiness Zone
                {renderTooltip("Select your USDA Hardiness Zone")}
              </label>
              <Controller
                name="farm_info.hardiness_zone"
                control={control}
                rules={{ required: "Hardiness zone is required" }}
                render={({ field }) => (
                  <div>
                    <select
                      {...field}
                      id="hardiness-zone"
                      className={`mt-1 block w-full rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
                        errors.farm_info?.hardiness_zone ? 'border-red-500 focus:border-red-500 focus:ring-red-200' : 'border-gray-300 focus:border-gray-300 focus:ring-gray-200'
                      }`}
                    >
                      <option value="">Select a zone</option>
                      {hardinessZones.map(zone => (
                        <option key={zone} value={zone}>
                          {zone}
                        </option>
                      ))}
                    </select>
                    {errors.farm_info?.hardiness_zone && <p className="text-red-500 text-xs mt-1">{errors.farm_info.hardiness_zone.message}</p>}
                  </div>
                )}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="first-frost-date">
                First Frost Date
                {renderTooltip("Enter the average date of the first frost in your area")}
              </label>
              <Controller
                name="farm_info.first_frost_date"
                control={control}
                rules={{ required: "First frost date is required" }}
                render={({ field }) => (
                  <div>
                    <input
                      {...field}
                      id="first-frost-date"
                      type="date"
                      className={`mt-1 block w-full rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
                        errors.farm_info?.first_frost_date ? 'border-red-500 focus:border-red-500 focus:ring-red-200' : 'border-gray-300 focus:border-gray-300 focus:ring-gray-200'
                      }`}
                    />
                    {errors.farm_info?.first_frost_date && <p className="text-red-500 text-xs mt-1">{errors.farm_info.first_frost_date.message}</p>}
                  </div>
                )}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="last-frost-date">
                Last Frost Date
                {renderTooltip("Enter the average date of the last frost in your area")}
              </label>
              <Controller
                name="farm_info.last_frost_date"
                control={control}
                rules={{ required: "Last frost date is required" }}
                render={({ field }) => (
                  <div>
                    <input
                      {...field}
                      id="last-frost-date"
                      type="date"
                      className={`mt-1 block w-full rounded-md shadow-sm focus:ring focus:ring-opacity-50 ${
                        errors.farm_info?.last_frost_date ? 'border-red-500 focus:border-red-500 focus:ring-red-200' : 'border-gray-300 focus:border-gray-300 focus:ring-gray-200'
                      }`}
                    />
                    {errors.farm_info?.last_frost_date && <p className="text-red-500 text-xs mt-1">{errors.farm_info.last_frost_date.message}</p>}
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Add New Crop</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="crop-name">
                Crop Name
                {renderTooltip("Enter the name of the crop")}
              </label>
              <Controller
                name="new_crop.name"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    id="crop-name"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
                    placeholder="Enter crop name"
                  />
                )}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="start-indoor-weeks">
                Weeks to Start Indoors
                {renderTooltip("Enter the number of weeks before the last frost date to start seeds indoors")}
              </label>
              <Controller
                name="new_crop.start_indoor_weeks"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    id="start-indoor-weeks"
                    type="number"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
                    placeholder="0"
                  />
                )}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="transplant-weeks">
                Weeks After Last Frost to Transplant
                {renderTooltip("Enter the number of weeks after the last frost date to transplant seedlings outdoors")}
              </label>
              <Controller
                name="new_crop.transplant_weeks_after_last_frost"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    id="transplant-weeks"
                    type="number"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
                    placeholder="0"
                  />
                )}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="direct-sow-weeks">
                Weeks After Last Frost to Direct Sow
                {renderTooltip("Enter the number of weeks after the last frost date to sow seeds directly in the garden")}
              </label>
              <Controller
                name="new_crop.direct_sow_weeks"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    id="direct-sow-weeks"
                    type="number"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
                    placeholder="0"
                  />
                )}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="harvest-weeks">
                Harvest Weeks
                {renderTooltip("Enter the number of weeks the crop can be harvested")}
              </label>
              <Controller
                name="new_crop.harvest_weeks"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    id="harvest-weeks"
                    type="number"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
                    placeholder="0"
                  />
                )}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="successions">
                Successions
                {renderTooltip("Enter the number of times you plan to plant this crop in succession")}
              </label>
              <Controller
                name="new_crop.successions"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    id="successions"
                    type="number"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
                    placeholder="1"
                  />
                )}
              />
            </div>
            <div>
              <label className="block text-sm font-medium mb-1" htmlFor="days-to-maturity">
                Days to Maturity
                {renderTooltip("Enter the average number of days from planting to harvest")}
              </label>
              <Controller
                name="new_crop.days_to_maturity"
                control={control}
                render={({ field }) => (
                  <input
                    {...field}
                    id="days-to-maturity"
                    type="number"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-300 focus:ring focus:ring-gray-200 focus:ring-opacity-50"
                    placeholder="0"
                  />
                )}
              />
            </div>
          </div>
          <button
            type="button"
            onClick={addCrop}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            <Plus className="h-4 w-4 mr-2" />
            Add Crop
          </button>
        </div>

        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-xl font-semibold mb-4">Crops List</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Crop Name</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start Indoors</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Transplant</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Direct Sow</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Harvest</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Successions</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Days to Maturity</th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {crops.map((crop, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">{crop.name}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{crop.start_indoor_weeks}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{crop.transplant_weeks_after_last_frost}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{crop.direct_sow_weeks}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{crop.harvest_weeks}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{crop.successions}</td>
                    <td className="px-6 py-4 whitespace-nowrap">{crop.days_to_maturity}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button onClick={() => removeCrop(index)} className="text-red-600 hover:text-red-900">
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <button 
          type="submit" 
          disabled={isLoading || crops.length === 0 || isComplete} 
          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isLoading ? 'Generating...' : isComplete ? 'Complete' : 'Generate Crop Plan'}
        </button>
      </form>

      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-500 mx-auto mb-4"></div>
            <p className="text-center">Generating Crop Plan...</p>
          </div>
        </div>
      )}

      <div className="fixed bottom-0 right-0 p-6 space-y-4">
        {toasts.map(toast => (
          <div key={toast.id} className={`p-4 rounded-md shadow-lg ${toast.type === 'success' ? 'bg-green-500' : 'bg-red-500'} text-white`}>
            {toast.message}
          </div>
        ))}
      </div>
    </div>
  );
}

export default CropPlanner;